.error-page-container{
	width: 100%;
	float: left;
}
.error-inner-content{
	width: 100%;
	float: left;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 15%;
}
.error-img{
	width: auto;
	float: left;
}
.error-page-title{
	width: 100%;
	float: left;
	font-size: 63px;
    font-weight: 400;
    color: #000;
    text-align: center;
}
.error-page-text{
	width: 50%;
	float: left;
	font-size: 24px;
    font-weight: 400;
    color: #000;
    text-align: center;
    line-height: 30px;
    margin: 10px 0px;
}
.error-page-text a{
	color: #f64071;
}
.error-page-text a:hover{
	color: #f64071;
}

@media (max-width: 1200px) {
.error-page-text {
    width: 80%;
}
.error-inner-content{
	padding-top: 10%;
}		
}
@media (max-width: 1024px) {
.error-page-text {
    width: 80%;
}
.error-inner-content{
	padding-top: 20%;
}	
}
@media (max-width: 992px) {
.error-page-text {
    width: 100%;
}
.error-inner-content{
	padding-top: 25%;
}
}