.menu-item-scroll{
	height: 550px;
	overflow-y: scroll;
}
.order-details-container .ant-modal-title{
	font-weight: 700;
	font-size: 20px;
}
.order-details-container .menu-title{
	font-size: 16px;
	color: #f64071;
	font-weight: 600;
}
.order-details-container .menu-variant{
	font-size: 14px;
	color: #000;
	font-weight: 300;
	padding-left: 30px;
}
.order-details-container .menu-taxes{
	font-size: 14px;
	color: #000;
	font-weight: 500;
}
.order-details-container .menu-price{
	font-size: 14px;
	color: #000;
	font-weight: 700;
}
.order-details-container .footer-menu-taxes{
	font-size: 14px;
	color: #000;
	font-weight: 500;
}
.order-details-container .footer-menu-price{
	font-size: 16px;
	color: #000;
	font-weight: 500;
}
.order-details-container .footer-menu-total{
	font-size: 18px;
	color: #000;
	font-weight: 700;
}
.order-details-container .ant-switch-checked {
    background-color: #f64071;
}
.border-divided{
	border-top: 1px dashed #c1c1c1;
	margin: 15px 0;
}
.order-details-container .text-line-through{
	text-decoration: line-through;
	opacity: 0.3;
}
.order-details-container .card {
	border: 1px solid #edeff1;
	margin-bottom: 10px;
}
.order-details-container .card .card-header{
	background-color: transparent;
	border-bottom: 1px solid #edeff1;
	padding: 10px 15px;
}
.order-details-container .card .card-header a {
    color: #f64071;
    text-decoration: none;
}
.order-details-container .card .card-header a:hover {
    color: #e93c9d;
}
.order-details-container .card .card-header .menu-price{
	font-size: 16px;
	font-weight: 700;
}
.order-details-container .ant-modal-body .form-control{
	height: 40px;
	font-size: 14px;
	color: #000;
}
.order-details-container .btn-deny{
	height: 38px;
	background: #ff0000;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	border: 1px solid #ff0000;
	padding: 7px 20px;
}
.order-details-container .btn-deny:hover{
	background: #ff0000;
	color: #fff;
	border: 1px solid #ff0000;
}
.order-details-container .btn-reorder{
	height: 38px;
	background: #49c114;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	border: 1px solid #49c114;
	padding: 7px 20px;
}
.order-details-container .btn-reorder:hover{
	background: #49c114;
	color: #fff;
	border: 1px solid #49c114;
}
.order-details-container .btn-accept{
	height: 38px;
	background: #49c114;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	border: 1px solid #49c114;
	padding: 7px 20px;
}
.order-details-container .btn-accept:hover{
	background: #49c114;
	color: #fff;
	border: 1px solid #49c114;
}
.order-details-container .btn-waiting{
	height: 38px;
	background: #ff9900;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	border: 1px solid #ff9900;
	padding: 7px 20px;
}
.order-details-container .btn-waiting:hover{
	background: #ff9900;
	color: #fff;
	border: 1px solid #ff9900;
}
.order-details-container .btn-print{
	height: 38px;
	background: #379c0a;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	border: 1px solid #379c0a;
	padding: 7px 20px;
}
.order-details-container .btn-print:hover{
	background: #379c0a;
	color: #fff;
	border: 1px solid #379c0a;
}
.order-details-container .text-blue{
	color: #011dff;
}
.order-details-container .text-red{
	color: #cc0000;
}
.order-details-container .footer-text-paid{
	font-size: 20px;
	color: #49c114;
	font-weight: 700;
}
.order-details-container .footer-payment{
	font-size: 14px;
	color: #000;
	font-weight: 300;
}
.user-info-set-container {
    width: 100%;
    float: left;
	display: flex;
	font-size: 14px;
	font-weight: 400;
}
.user-info-set {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    border-right: 1px solid #000;
}
.user-info-set:first-child {
    padding-left: 0;
}
.user-info-set:last-child {
    border-right: none;
}
.customer-details-model-header{
	width: 100%;
	float:left;
	padding: 15px;
	padding-top: 0;
	border-bottom: 1px solid #e5e5e5;
	margin-bottom: 15px;
}
.customer-details-model-title{
	width: 100%;
	float: left;
	font-size: 18px;
	color: #000;
	font-weight: 700;
	margin-bottom: 10px;
	font-family: Arial, sans-serif;
}
.user-info-name{
	width: 100%;
	float: left;
	font-size: 13px;
	color: #000;
	font-weight: 500;
}
.order-details-container .ant-modal-header{
	width: 100%;
	float: left;
	display: none;
}
.order-details-container .ant-modal-body{
	width: 100%;
	float: left;
	background-color: #fff;
	border-radius: 15px;
}
.p-15{
	padding: 15px;
}
.order-details-container .footer-menu-taxes .badge{
    font-size: 13px;
	color:#000;
	font-weight: 300;
	border: 1px solid #f5f5f5;
	padding: 3px 6px;
	margin: 3px;
}
.order-details-container .ant-form-item{
	margin-bottom: 0;
}
.order-details-container .btn-preparing{
	height: 38px;
	background:#ffbe05;
	font-size: 14px;
	color: black;
	font-weight: 600;
	border: 1px solid #d0748b;
	padding: 7px 20px;
}
.order-details-container .btn-preparing:hover{
	background: #379c0a;
	color: #fff;
	border: 1px solid #379c0a
}
.order-details-container .btn-ready{
	height: 38px;
	background: #cbcd60;
	font-size: 14px;
	color: #fff;
	font-weight: 600;
	border: 1px solid #cbcd60;
	padding: 7px 20px;
}
.order-details-container .btn-ready:hover{
	background: #ecf00d;
	color: #fff;
	border: 1px solid #ecf00d;
}
.user-info-details{
	font-family: Arial, sans-serif;
}
.print-bill-table th,b{
	font-family: Arial, sans-serif;
}
.print-bill-table td{
	font-family: Arial, sans-serif;
}
.user-info-set-kot-container {
    width: 100%;
    /* float: left; */
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	font-family: Arial, sans-serif;
}
.user-info-set-bill-container{
	width: 100%;
    /* float: left; */
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	text-align: center;
	font-family: Arial, sans-serif;
}
.fw-bold{
	font-weight: bold;
}