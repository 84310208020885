.section-title-content {
  width: 100%;
  float: left;
}
.section-title {
  width: 100%;
  float: left;
  font-size: 30px;
  font-weight: 400;
  line-height: 30px;
  color: #000;
  margin: 15px 0px;
}
.row-content {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
}
.top-dishes-container {
  width: 100%;
  float: left;
  padding: 15px 30px;
}
.top-dishes-set {
  width: 14.8%;
  float: left;
  margin: 10px 15px;
}
.top-dishes-box {
  width: 100%;
  float: left;
  background: transparent;
  padding: 15px;
  border-radius: 20px;
  border: solid 1px transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.top-dishes-box:hover {
  background: #fff;
  border: solid 1px #d5d7da;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.top-dishes-img-content {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}
.top-dishes-img {
  width: 140px;
  height: 140px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden;
}
.top-dishes-close-btn-content {
  width: 100%;
  float: left;
  position: relative;
}
.top-dishes-close-btn {
  font-size: 24px;
  font-weight: 400;
  color: #bfbfbf;
  background: transparent;
  outline: none;
  border: none;
  position: absolute;
  right: 0;
  float: right;
}
.top-dishes-detail {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.top-dishes-name {
  width: 100%;
  height: 54px;
  float: left;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  margin: 5px 0px;
}
.top-dishes-rating-and-edit-set {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
}
.top-dishes-rating {
  width: 50%;
  float: left;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.rating-count {
  font-weight: 400;
  color: #bfbfbf;
}
.top-dishes-edit-icon {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #bfbfbf;
  outline: none;
  border: none;
  background: transparent;
  line-height: 20px;
}
.divider {
  width: 100%;
  float: left;
  border-bottom: solid 1px #d0d2d5;
  margin: 40px 0px;
}
.rating-and-count-filter-set {
  width: 100%;
  float: left;
  margin: 15px 0px;
}
.rating-filter {
  width: auto;
  float: right;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 40px;
}
.rating-filter-icon {
  float: right;
  font-size: 24px;
  font-weight: 400;
  color: #bfbfbf;
  outline: none;
  border: none;
  background: transparent;
  line-height: 20px;
}
.star-dishes-set {
  width: 12.4%;
  float: left;
  margin: 10px 15px;
}
.star-dishes-box {
  width: 100%;
  height: 270px;
  float: left;
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  border: solid 1px #d5d7da;
}
.star-dishes-box.selected {
  border: solid 1px #91e87f;
}
.star-dishes-box.already-selected {
  border: solid 1px #ffb9bc;
}
.star-dishes-box.selected .star-dishes-tick-btn {
  color: #5cb24b;
}
.star-dishes-box.already-selected .star-dishes-tick-btn {
  color: #ff313b;
}
.star-dishes-tick-btn {
  font-size: 26px;
  font-weight: 400;
  color: #bfbfbf;
  background: #fff;
  outline: none;
  border: none;
  position: absolute;
  top: -10px;
  right: -8px;
  float: right;
  padding: 0;
  height: 22px;
  width: 22px;
  line-height: 0;
  border-radius: 30px;
}
.star-dishes-img {
  width: 140px;
  height: 140px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  overflow: hidden;
}
.star-dishes-name {
  width: 100%;
  height: 54px;
  float: left;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  margin: 5px 0px;
}
.buy-more-card {
  width: 180px;
  height: 210px;
  float: right;
  background: #fff;
  border-radius: 20px;
  text-align: center;
  padding: 15px;
  position: relative;
  margin-top: 40px;
  border-style: dashed;
  border: dashed 1px #b6b6b8;
}
.buy-more-card .btn-success {
  color: #fff;
  background-color: #5cb24b;
  border-color: #5cb24b;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}
.buy-more-card-text {
  width: 100%;
  float: left;
  font-size: 16px;
  font-weight: 400;
  color: #999999;
}
.buy-more-card-sub-text {
  font-size: 12px;
  text-transform: uppercase;
}
.buy-more-card-bottom-set {
  width: 100%;
  float: left;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 15px;
}
.buy-more-card-price {
  width: 100%;
  float: left;
  font-size: 22px;
  font-weight: 700;
  color: #000;
  line-height: 20px;
}
.buy-more-card-price-text {
  font-size: 15px;
  font-weight: 400;
  color: #000;
}
.like-heart-set {
  width: 30px;
  position: relative;
}
.heart {
  width: 30px;
  height: 25px;
  float: left;
  position: relative;
  margin-right: 10px;
}
.heart:before,
.heart:after {
  position: absolute;
  content: "";
  left: 15px;
  top: 0;
  width: 15px;
  height: 23px;
  background: #443c77;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.heart:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.heart-fill-range {
  float: left;
  position: absolute;
  overflow: hidden;
}
.heart-fill {
  position: relative;
  width: 30px;
  height: 25px;
  float: left;
}
.heart-fill:before,
.heart-fill:after {
  position: absolute;
  content: "";
  left: 15px;
  top: 0;
  width: 15px;
  height: 23px;
  background: #f64071;
  -moz-border-radius: 50px 50px 0 0;
  border-radius: 50px 50px 0 0;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform-origin: 0 100%;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -o-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.heart-fill:after {
  left: 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .top-dishes-set {
    width: 44.22222%;
  }
  .star-dishes-set {
    width: 27.555555%;
  }
  .top-dishes-name {
    font-size: 18px;
  }
  .top-dishes-rating {
    font-size: 14px;
  }
  .rating-filter {
    font-size: 14px;
    margin-left: 15px;
  }
  .rating-filter-icon {
    font-size: 20px;
  }
  .star-dishes-tick-btn {
    font-size: 22px;
  }
  .star-dishes-name {
    font-size: 18px;
  }
}
/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .top-dishes-set {
    width: 29.444444%;
  }
  .star-dishes-set {
    width: 21.111111%;
  }
  .top-dishes-name {
    font-size: 18px;
  }
  .top-dishes-rating {
    font-size: 16px;
  }
  .rating-filter {
    font-size: 16px;
    margin-left: 15px;
  }
  .rating-filter-icon {
    font-size: 20px;
  }
}
/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .top-dishes-set {
    width: 17.222222%;
  }
  .star-dishes-set {
    width: 17.222222%;
  }
}
@media (min-width: 1440px) {
  .top-dishes-set {
    width: 17.222222%;
  }
  .star-dishes-set {
    width: 17.222222%;
  }
  .top-dishes-name {
    font-size: 18px;
  }
  .star-dishes-name {
    font-size: 18px;
  }
  .top-dishes-rating {
    font-size: 18px;
  }
  .rating-filter {
    font-size: 20px;
    margin-left: 40px;
  }
  .rating-filter-icon {
    font-size: 24px;
  }
}
@media (min-width: 1600px) {
  .top-dishes-set {
    width: 14.8%;
  }
  .star-dishes-set {
    width: 12.4%;
  }
}

.no-content-container {
  width: 100%;
  height: 255px;
  float: left;
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  border: solid 1px #ececec;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.no-content-container .icon {
  font-size: 40px;
  color: #d5d7da;
}
.no-content-container .text {
  font-size: 14px;
  color: #d5d7da;
}

.top-dishes-box-add {
  width: 100%;
  height: 272px;
  float: left;
  background: #fff;
  font-size: 40px;
  color: #d5d7da;
  padding: 15px;
  border-radius: 20px;
  border: solid 1px #d5d7da;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn.add-more-text {
  font-size: 13px;
  color: #828282;
  font-weight: 300;
  cursor: default;
}
.btn-addmore-dish {
  font-size: 13px;
}
.cursor-pointer{
  cursor: pointer;
}

.antd-input-number.input{
  height: 44px;
  line-height: 44px;
  width: 100%;
}

.input-container label {
  width: 100%;
  float: left;
}

.modal-mid-content-form {
  width: 100%;
  float: left;
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.buy-more-modal-container .ant-btn-primary-disabled, .buy-more-modal-container .ant-btn-primary.disabled, .buy-more-modal-container .ant-btn-primary[disabled], .buy-more-modal-container .ant-btn-primary-disabled:hover, .buy-more-modal-container .ant-btn-primary.disabled:hover, .buy-more-modal-container .ant-btn-primary[disabled]:hover, .buy-more-modal-container .ant-btn-primary-disabled:focus, .buy-more-modal-container .ant-btn-primary.disabled:focus, .buy-more-modal-container .ant-btn-primary[disabled]:focus, .buy-more-modal-container .ant-btn-primary-disabled:active, .buy-more-modal-container .ant-btn-primary.disabled:active, .buy-more-modal-container .ant-btn-primary[disabled]:active, .buy-more-modal-container .ant-btn-primary-disabled.active, .buy-more-modal-container .ant-btn-primary.disabled.active, .buy-more-modal-container .ant-btn-primary[disabled].active {
  color: #fff;
  background-color: #f64071;
  border-color: #f64071;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.error {
  color: red;
  font-size: 14px;
}

.dish-load-more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 15px;
}
