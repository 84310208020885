.bottom-menu-list{
  width: 100%;
  float: left;
  position: absolute;
  bottom: 0;
}
.nav-side-menu {
  overflow: auto;
  font-size: 16px;
  font-weight: 400;
  background-color: #261c38;
  top: 0px;
  padding-left: 0px;
  height: 100%;
  width: 100%;
  color: #e1ffff;
  text-align: left;
}
.nav-side-menu .brand {
  background-color: #261c38;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
}
.nav-side-menu .toggle-btn {
  display: none;
}
.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 55px;
  cursor: pointer;
}
.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f13a";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  float: right;
  font-weight: 900;
}
.nav-side-menu ul .active,
.nav-side-menu li .active {
  background-color: #f64071;
}
.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  color: #d19b3d;
}
.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  color: #d19b3d;
}
.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background-color: #181c20;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}
.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  background-color: #f64071;
}
.nav-side-menu ul .sub-menu li:before,
.nav-side-menu li .sub-menu li:before {
  font-family: 'Font Awesome 5 Free';
  content: "\f105";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  font-weight: 900;
}
.nav-side-menu li {
  padding-left: 5px;
  position: relative;
}
.nav-side-menu li a {
  text-decoration: none;
  color: #fff;
  padding: 0;
}
.nav-side-menu li a i {
  padding-left: 15px;
  width: 20px;
  padding-right: 42px;
  font-size: 26px;
  vertical-align: middle;
}
.nav-side-menu li:hover {
  background-color: #f64071;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
.jquery-accordion-menu-label {
    min-width: 30px;
    padding: 1px 2px 1px 1px;
    position: absolute;
    right: 18px;
    top: 19px;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    line-height: 18px;
    background: #f64071;
    border-radius: 60px;
    font-family: 'Montserrat', sans-serif;
}
.nav-side-menu li:hover .jquery-accordion-menu-label{
  background: #fff;
  color: #f64071;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}
@media (max-width: 767px) {
  .nav-side-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .nav-side-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    color: #fff;
    width: 40px;
    text-align: center;
  }
  .brand {
    text-align: left !important;
    font-size: 22px;
    padding-left: 20px;
    line-height: 50px !important;
  }
}
@media (min-width: 767px) {
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }
}