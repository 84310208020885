.transactions-page-container{
	width: 100%;
	float: left;
	padding: 15px 30px;
}
.customer-table-filter-set{
	width: 100%;
	float: left;
	margin-bottom: 20px;
}
.customer-filter-set{
	width: 16.5%;
	float: left;
	margin: 10px 0px;
}
.customer-table{
	width: 100%;
	float: left;
}
.table {
    background: #fff;
}
.customer-table .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}
.customer-table .table-striped tbody tr:nth-of-type(even) {
    background-color: #f5f7fa;
}
.customer-count-text{
	width: auto;
	float: left;
	font-size: 20px;
	font-weight: 400;
	color: #000;
	margin: 10px 0px;
}
.customer-filter-set Input{
	width: 55px;
	margin-right: 15px;
	text-align: right;
}
.customer-filter-set .ant-checkbox-inner {
    width: 32px;
    height: 32px;
}
.customer-filter-set .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
    border-color: #fff;
}
.customer-filter-set .ant-checkbox-wrapper:hover .ant-checkbox-inner, .customer-filter-set .ant-checkbox:hover .ant-checkbox-inner, .customer-filter-set .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #dcdee1;
}
.customer-filter-set .ant-checkbox-checked .ant-checkbox-inner::after {
    border: 3px solid #f64071;
    border-top-color: rgb(246, 64, 113);
    border-top-style: solid;
    border-top-width: 2px;
    border-left-color: rgb(246, 64, 113);
    border-left-style: solid;
    border-left-width: 2px;
    border-top: 0;
    border-left: 0;
}
.customer-filter-set .ant-checkbox-inner::after {
    width: 8.714px;
    height: 16.143px;
    border: 2px solid #fff;
    left: 25%;
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
.customer-filter-set {
    width: 50%;
}
.customer-filter-set Input {
    width: 47px;
}
.filter-label {
    font-size: 13px;
}
}
/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
.customer-filter-set {
    width: 50%;
}
}
/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
.customer-filter-set {
    width: 25%;
}
}
@media (min-width: 1440px) {
.customer-filter-set {
    width: 25%;
}
}
@media (min-width: 1600px) {
.customer-filter-set {
    width: 16.5%;
}
}

.ant-spin-spinning.transactions-table-spinner {
    width: 100%;
    height: 200px;
    display: flex ;
    align-items: center;
    justify-content: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customer-filter-set .ant-input-number {
    margin-right: 10px;
}
