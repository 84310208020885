.header-container{
	width: calc(100% - 250px);
	float: left;
	background: #fff;
	border-bottom: solid 1px #dcdee1;
	padding: 0 15px;
	position: fixed;
	top: 158px;
	margin-top: -158px;
	overflow-y: hidden;
  	overflow-x: hidden;
  	z-index: 6;
}
.header-left{
	width: 100%;
	height: 158px;
	float: left;
	display: flex;
	align-items: center;
	padding: 0 15px;
}
.header-logo-content{
	width: 103px;
	float: left;
}
.restaurant-logo{
	width: 80px;
    height: 80px;
    border-radius: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
	/* width: 100%;
	height: auto; */
}
.restaurant-detail-content{
	width: 100%;
	float: left;
}
.restaurant-name{
	width: 100%;
	float: left;
	font-size: 36px;
	font-weight: 400;
	line-height: 36px;
	color: #000;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.restaurant-location{
	width: 100%;
	float: left;
	font-size: 21px;
	font-weight: 400;
	color: #000;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.restaurant-contact{
	width: 100%;
	float: left;
	font-size: 14px;
	font-weight: 400;
	color: #999999;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.restaurant-edit-icon{
	font-size: 30px;
	font-weight: 400;
	color: #bfbfbf;
	background: transparent;
	outline: none;
	border: none;
}
.header-right{
	height: 120px;
    padding: 22px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.header-right a{
	color: #000 !important;
	text-decoration: none !important;
}
.m-lr-10 {
	margin: 0px 10px;
}
.count-set{
	width: auto;
	float: left;
	margin-left: 19px;
}
.count-icon{
	float: left;
	font-size: 50px;
	font-weight: 400;
	color: #bfbfbf;
	position: relative;
}
.count-icon:after{
	width: 26px;
	height: 26px;
	font-size: 20px;
	font-weight: 400;
	color: #ffba00;
	content: "\f2d5";
	font-family: FontAwesome;
	position: absolute;
	top: 0px;
	right: -10px;
	background: #fff;
	border-radius: 50px;
	text-align: center;
	line-height: 26px;
}
.count-type-set{
	display: flex;
	flex-direction: column;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
	padding-left: 15px;
	padding-top: 15px;
}
.count-text{
	width: 100%;
	font-size: 22px;
	font-weight: 400;
	color: #000;
	line-height: 20px;
	font-family: 'Montserrat', sans-serif;
}
.count-type-text{
	width: 100%;
	font-size: 12px;
	font-weight: 400;
	color: #c9c9c9;
}
.count-set .btn-outline-secondary {
    color: #bababa;
    border-color: #bababa;
    outline: none;
    text-transform: uppercase;
    font-size: 10px;
}
.count-set .btn-success{
	color: #fff;
	background-color: #5cb24b;
	border-color: #5cb24b;
	outline: none;
	border: none;
	text-transform: uppercase;
	font-size: 10px;
	font-weight: 700;
}

@media (max-width: 1199.98px) {
.header-right {
    height: auto;
	padding: 20px 15px;
	align-items: flex-start;
}
}
@media (max-width: 1800px) {
.header-container {
    width: calc(100% - 250px);
}
.header-left {
    height: 120px;
}
.header-right {
    height: 120px;
    padding: 22px 15px;
}
.restaurant-name {
    font-size: 26px;
    line-height: 26px;
}
.restaurant-edit-icon {
    font-size: 24px;
}
.restaurant-location {
    font-size: 17px;
}
.count-set {
    margin-left: 20px;
}
.restaurant-contact {
    font-size: 12px;
}
.count-icon {
    font-size: 32px;
}
.count-icon::after {
    width: 22px;
    height: 22px;
    font-size: 16px;
}
.count-type-set {
    padding-top: 8px;
    padding-left: 10px;
}
.count-text {
    font-size: 16px;
}
.count-type-text {
    font-size: 10px;
}
}
@media (max-width: 1366px) {
.header-container {
    width: calc(100% - 215px);
}
}
.count-set .count-icon img{
	width: 30px;
margin-right: 5px;

}
