.hour-deal-container{
	width: 100%;
	float: left;
	padding: 40px 0;
}
.hour-deal-container .table thead th {
    vertical-align: middle;
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.hour-deal-container .table td, .table th{
	vertical-align: middle;
	font-size: 14px;
}
.hour-deal-container .table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #fff;
}
.hour-deal-container .table-striped tbody tr:nth-of-type(2n) {
    background-color: #f5f7fa;
}
.hour-deal-container .row-opacity{
	opacity: .2;
	text-decoration: line-through;
}
.hour-deal-container .table tbody td .ant-switch-disabled {
    cursor: not-allowed;
    opacity: 1;
}
.hour-deal-container .ant-switch-checked {
    background-color: #f64071;
}

/* Hour form container  */
.hour-deal-form-container {
	width: 100%;
	float: left;
	margin: 40px 0;
}
.hour-deal-form{
	width: 100%;
	float: left;
}
.hour-deal-form label.deal-label{
	width: 100%;
	font-size: 14px;
	font-weight: 500;
	color: #000;
	text-transform: uppercase;
	line-height: 20px;
}
.hour-deal-form .ant-radio {
    vertical-align: middle;
}
.hour-deal-form .ant-radio-checked .ant-radio-inner {
    border-color: #f64071 !important;
}
.hour-deal-form .ant-radio-inner::after {
    background-color: #f64071;
}
.hour-deal-form .btn-submit{
	height: auto;
	background-color: #f64071;
	font-size: 14px;
	color: white;
	font-weight: 500;
	border: none;
	outline: none;
	border-radius: 2px;
	border-radius: .25rem;
	cursor: pointer;
	box-shadow: none !important;
	padding: 7px 40px;
}
.hour-deal-form .btn-submit:hover,
.hour-deal-form .btn-submit:focus{
	background-color: #f64071;
	color: #fff;
}