.store-analytics-container{
	width: 100%;
	float: left;
	padding: 15px 30px;
}
.top-graph-set{
	width: 100%;
	float: left;
	padding: 15px;
}
.top-graph-title-set{
	width: auto;
	float: left;
	margin: 15px 0px;
}
.top-graph-filter-set{
	width: auto;
	float: right;
	margin: 28px 0px;
}
.top-graph-filter{
	width: auto;
	float: left;
}
.filter-btn{
    position: relative;
    display: inline-block;
    padding: 4px 8px;
    margin-left: -1px;
    line-height: 1.25;
    color: #a1a1a1;
    font-size: 14px;
    font-weight: 700;
    background-color: #fff;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    border-left: 0px solid #dee2e6;
    border-right: 0px solid #dee2e6;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
}
.top-graph-filter-set .filter-btn:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-left: 1px solid #dee2e6;
}
.top-graph-filter-set .filter-btn:last-child {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-right: 1px solid #dee2e6;
}
.filter-btn.active{
	color: #fff;
    background-color: #b2b2b2;
}
.range-btn{
    position: relative;
    display: inline-block;
    padding: 4px 8px;
    margin-left: -1px;
    line-height: 1.25;
    color: #a1a1a1;
    font-size: 14px;
    font-weight: 700;
    background-color: #fff;
    border: 1px solid #dee2e6;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    border-radius: .25rem;
    text-transform: uppercase;
}
.range-btn:hover{
	color: #fff;
	background-color: #b2b2b2;
}
.top-graph-title{
	width: auto;
	float: left;
	font-size: 36px;
	font-weight: 400;
	color: #000;
}
.top-graph{
	width: 100%;
	float: left;
	padding: 15px 0px;
}
.bottom-graph-box{
	width: 100%;
	float: left;
	padding: 0 15px;
	background: #fff;
	border: solid 1px #d5d7da;
}
.bottom-graph-title-set{
	width: auto;
	float: left;
	margin: 24px 0px;
}
.bottom-graph-title{
	width: auto;
	float: left;
	font-size: 24px;
	font-weight: 400;
	color: #000;
}
.bottom-graph-content{
	width: 430px;
	width: 100%;
	float: left;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
	margin-bottom: 30px;
}
.bottom-graph{
	width: 430px;
	float: left;
}

@media (max-width: 1800px) {
	.top-graph-title{
		font-size: 22px;
	}
	.top-graph-filter-set {
    	margin: 18px 0px;
	}
	.bottom-graph-title-set {
    	margin: 18px 0px;
	}
	.bottom-graph-title {
	    font-size: 18px;
	}
	.filter-btn {
	    padding: 2px 6px;
	    font-size: 12px;
	}
	.range-btn {
    	padding: 2px 8px;
    	font-size: 12px;
	}
}
@media (max-width: 1200px) {
.top-graph-title {
    font-size: 20px;
}
}