
.login-page-container{
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
}
.login-form-container{
	width: 100%;
	float: left;
}
.login-welcome-title{
	width: 100%;
	float: left;
	font-size: 28px;
    font-weight: 400;
    color: #000;
    text-align: center;
}
.login-sub-title{
	width: 100%;
	float: left;
	font-size: 16px;
    font-weight: 400;
    color: #9b9c9e;
    text-align: center;
}
.login-form-content{
	width: 100%;
	float: left;
	background-color: #fff;
	border-radius: 20px;
	margin: 20px 0px;
	-moz-box-shadow: 0px 10px 15px rgba(68, 68, 68, 0.2);
	-webkit-box-shadow: 0px 10px 15px rgba(68, 68, 68, 0.2);
	box-shadow: 0px 10px 15px rgba(68, 68, 68, 0.2);
}
.login-form-header{
	width: 100%;
	float: left;
	background-color: #f64071;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	padding: 15px 0px;
	text-align: center;
}
.login-form-header-logo{
	width: 100%;
	float: left;
}
.login-form-header-logo-text{
	font-size: 20px;
    font-weight: 400;
    color: #fff;
    vertical-align: bottom;
}
.login-form-inner-content{
	width: 100%;
	/* height: 285px; */
	float: left;
	padding: 50px 72px;
}
.ant-form-explain, .ant-form-extra {
    min-height: 0;
    margin-top: -10px;
}
.login-form-inner-content .ant-input {
    margin-bottom: 10px;
}
.login-form-bottom-set{
	width: 100%;
	float: left;
}
.login-form-bottom-link{
	width: 100%;
	float: left;
	font-size: 16px;
    font-weight: 400;
    color: #7a7b7d;
    padding: 5px 0;
}
.login-form-bottom-link a{
    color: #7a7b7d;
}
.login-form-bottom-link a:hover{
    color: #7a7b7d;
}
@media (max-width: 1366px) {

}
@media (max-width: 1200px) {
.login-form-container {
    margin-top: 20%;
}	
}
@media (max-width: 1024px) {
.login-form-container {
    margin-top: 45%;
}	
}
@media (max-width: 992px) {
.login-form-container {
    margin-top: 45%;
}	
}

.login-form-content .ant-form label {
	line-height: 15px;
}

.login-page-container .ant-form-item {
    margin-bottom: 10px;
}

.login-form-inner-content .ant-input-affix-wrapper .ant-input-prefix, 
.login-form-inner-content .ant-input-affix-wrapper .ant-input-suffix { 
	top: 40%;
}