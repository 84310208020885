.orders-page-container {
    width: 100%;
    float: left;
    padding: 15px 30px;
    margin-bottom: 144px;
}

.orders-set {
    /* width: 18.122222%; */
    width: 21%;
    float: left;
    margin: 10px 15px;
    cursor: pointer;
}

.row-content {
    width: 100%;
    float: left;
    display: flex;
    flex-wrap: wrap;
}

.orders-box {
    width: 100%;
    float: left;
    background: #fff;
    padding: 8px 10px;
    border-radius: 5px;
    border: solid 1px #d5d7da;
    display: flex;
    align-items: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.orders-box:hover .order-close-btn{
    opacity: 1;
    transform: scale(1);
}

.orders-user-image {
    width: 57px;
    height: 57px;
    float: left;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.orders-user-detail {
    width: calc(100% - 75px);
    float: left;
    margin-left: 15px;
}

.orders-user-name {
    width: 100%;
    float: left;
    font-size: 19px;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.user-visit-count {
    width: 100%;
    float: left;
    font-size: 14px;
    font-weight: 400;
    color: #a1a1a1;
    line-height: 24px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.order-count-set {
    float: right;
    position: absolute;
    right: 10px;
}

.order-count {
    width: 100%;
    font-size: 50px;
    font-weight: 400;
    color: #000;
    text-align: right;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.order-close-btn{
    width: 26px;
    height: 26px;
    border-radius: 30px;
    font-size: 14px;
    color: #000;
    line-height: 26px;
    text-align: center;
    position: absolute;
    top: -12px;
    right: -12px;
    outline: none;
    border: none;
    background-color: #fff;
    -moz-box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    -webkit-box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    opacity: 0;
    transition: all 300ms;
    transform: scale(0);
}
.orders-box.orders-box-active {
    color: #fff;
    background: #f64071;
    border: solid 1px #d5d7da;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.orders-box.orders-box-active .orders-user-name,
.orders-box.orders-box-active .user-visit-count,
.orders-box.orders-box-active .order-count {
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.order-sort-filter {
    width: auto;
    float: right;
    vertical-align: middle;
    margin-left: 15px;
}

.order-sort-filter .ant-select-selection__placeholder,
.ant-select-search__field__placeholder {
    color: #a1a1a1;
}

/* Notification Style Start */
#notification-scroll-container {
    width: 350px;
    overflow: auto;
    margin: auto;
    position: relative;
}
.notification-container{
    width: 350px;
    max-height: 372px;
    background-color: #fff;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 20;
    -moz-box-shadow: 0px 0px 10px rgba(68, 68, 68, 0.2);
    -webkit-box-shadow: 0px 0px 10px rgba(68, 68, 68, 0.2);
    box-shadow: 0px 0px 10px rgba(68, 68, 68, 0.2);
    padding: 0 0px 8px 0px;
    margin: 10px;
    border-radius: 4px;
    overflow: hidden;
}
.notification-content-title{
    width: 100%;
    float: left;
    padding: 15px;
    padding-bottom: 5px;
}
.notification-content-close-btn{
    float: right;
    border-radius: 30px;
    line-height: 20px;
    font-size: 20px;
    text-align: center;
    outline: none;
    color: #000;
    border: none;
    padding: 0;
    background-color: transparent;
    opacity: 0.4;
    transition: ease all 0.6s;
}
.notification-content-close-btn:hover{
    opacity: 1;
    transition: ease all 0.6s;
}
.notification-box-container{
    width: 100%;
    float: left;
    padding: 0px 15px;
}
.notification-box{
    width: 100%;
    min-height: 90px;
    float: left;
    background-color: #fff;
    border-radius: 5px;
    border: solid 1px #d5d7da;
    margin: 8px 0;
    padding: 5px 15px;
    position: relative;
    display: flex;
}
.notification-box-inner{
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.notification-box-icon{
    width: 62px;
    height: 62px;
    float: left;
    font-size: 40px;
    padding-right: 15px;
}
.notification-box-title{
    width: 100%;
    float: left;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    margin: 5px 0px;
}
.notification-box-text{
    width: 100%;
    float: left;
    font-size: 14px;
    color: #000;
    font-weight: 400px;
    margin: 5px 0px;
    padding-right: 15px;
    padding-left: 15px;
}
.notification-close-btn{
    float: right;
    border-radius: 30px;
    font-size: 14px;
    text-align: center;
    outline: none;
    border: none;
    padding: 0;
    background-color: transparent;
    opacity: 0.6;
    transition: ease all 0.6s;
    position: absolute;
    right: 10px;
}
.notification-close-btn:hover{
    opacity: 1;
    transition: ease all 0.6s;
}
.notification-order-table{
    font-size: 40px;
}
.visit{
    background-color: #2acd7217;
    border: solid 1px #2ECC71;
    color: #2ECC71;
}
.order{
    background-color: #8e44ad17;
    border: solid 1px #8E44AD;
    color: #8E44AD;
}
.utilities{
    background-color: #50B9FF1C;
    border: solid 1px #3498DB;
    color: #3498DB;
}
/* Notification Style End */

/* Animation Effect Style Start */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.show-notification {
  animation-name: fadeInUp;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.hide-notification {
  animation-name: fadeOutUp;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.open-notification {
  animation-name: fadeInUp;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.close-notification {
  animation-name: fadeOutDown;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.animated.infinite {
  animation-iteration-count: infinite;
}
.animated.delay-1s {
  animation-delay: 1s;
}
.animated.delay-2s {
  animation-delay: 2s;
}
.animated.delay-3s {
  animation-delay: 3s;
}
.animated.delay-4s {
  animation-delay: 4s;
}
.animated.delay-5s {
  animation-delay: 5s;
}
.animated.fast {
  animation-duration: 800ms;
}
.animated.faster {
  animation-duration: 500ms;
}
.animated.slow {
  animation-duration: 2s;
}
.animated.slower {
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    animation-duration: 1ms !important;
    transition-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}
/* Animation Effect Style End */

/* Modal style start */
/* Pending Request Star*/
.modal-container {
    width: 100%;
    float: left;
}

.modal-text {
    width: 100%;
    float: left;
    font-size: 21px;
    font-weight: 400;
    color: #000;
    text-align: center;
    margin-top: 20px;
}

.modal-mid-content {
    width: 100%;
    float: left;
    margin: 40px 0px;
    display: flex;
    /* justify-content: center; */
}

.modal-mid-icon {
    width: 72px;
    height: 72px;
    float: left;
}

.modal-mid-right {
    /* width: 150px; */
    width: 100%;
    float: left;
    margin-left: 15px;
}

.modal-mid-title {
    width: 100%;
    float: left;
    font-size: 31px;
    font-weight: 700;
    color: #000;
    line-height: 40px;
    text-transform: uppercase;
}

.modal-mid-text {
    width: 100%;
    float: left;
    font-size: 21px;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
}

/* Pending Request End*/

.order-incom-request-content {
    width: calc(100% - 250px);
    float: left;
    background: #fff;
    border-top: solid 1px #dcdee1;
    position: fixed;
    bottom: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1;
    padding: 20px;
}

.order-incom-request-content-title {
    width: 100%;
    float: left;
    font-size: 18px;
    font-weight: 400;
    color: #8b8b8b;
    text-transform: uppercase;
}
.incoming-customer-badge-content{
    width: auto;
    height: 77px;
    float: left;
}
.incoming-customer-badge {
    width: 165px;
    height: 46px;
    float: left;
    background: #f5f7fa;
    border: solid 2px #dcdee1;
    border-radius: 100px;
    display: flex;
    align-items: center;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    margin: 15px 15px 0px 0px;
}

.incoming-customer-img-badge {
    width: 40px;
    height: 40px;
    float: left;
    margin-left: 2px;
    background-size: cover;
    background-position: center center;
    border-radius: 50px;
    overflow: hidden;
}

.incoming-customer-name-badge {
    width: 95px;
    float: left;
    font-size: 19px;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
}

.incoming-customer-badge:hover {
    background: #fff;
    border: solid 2px #f64071;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.bell-request-count {
    width: 56px;
    height: 56px;
    float: left;
    font-size: 28px;
    font-weight: 400;
    color: #fff;
    position: relative;
    border-radius: 50%;
    text-align: center;
    line-height: 56px;
    margin: 10px 15px 0px 0px;
}

.bell-request-type-circle {
  width: 31px;
height: 31px;
float: left;
color: #000;
background: #ffffff;
position: absolute;
border-radius: 50%;
border: solid 2px #dcdee1;
top: -14px;
right: -9px;
font-size: 16px;
line-height: 30px;
}

.bell-request-type-circle i {
    font-size: 16px;
    float: left;
    padding: 6px;
}

.purple {
    background: #c38cd7;
}

.blue {
    background: #66ceff;
}

.orange {
    background: #ffa87d;
}

/* Order Received Modal Start */
.order-receive-row-set {
    width: 100%;
    float: left;
    padding: 20px 0;
    border-bottom: solid 1px #f0f0f0;
    display: flex;
    align-items: center;
}

.order-receive-text {
    width: 50%;
    float: left;
    font-size: 24px;
    font-weight: 400;
    color: #000;
}

.order-receive-text i {
    color: #828282;
    margin-right: 5px;
}

.order-receive-member-left-set {
    width: 70%;
    float: left;
    display: flex;
    align-items: center;
}

.order-receive-user-image {
    width: 83px;
    height: auto;
    float: left;
    border-radius: 100%;
}

.order-receive-user-detail {
    width: 100%;
    float: left;
    margin-left: 15px;
}

.order-receive-user-name {
    width: 100%;
    float: left;
    font-size: 34px;
    font-weight: 400;
    color: #000;
    line-height: 34px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-receive-user-location {
    width: 100%;
    float: left;
    font-size: 20px;
    font-weight: 400;
    color: #a1a1a1;
    line-height: 24px;
}

.order-receive-member-right-set {
    width: 30%;
    float: left;
}

.restaurant-count {
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5px;
}

.restaurant-count i {
    color: #ffba00;
}

.restaurant-user-visit-count {
    width: 100%;
    float: left;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    line-height: 24px;
    text-transform: uppercase;
}

.order-receive-member-info-content {
    width: 100%;
    float: left;
    padding: 15px 0;
    border-bottom: solid 1px #f0f0f0;
}

.order-receive-member-info-top {
    width: 100%;
    float: left;
    font-size: 20px;
    font-weight: 400;
    color: #b6b6b6;
    margin: 10px 0px;
    line-height: 15px;
}

.order-receive-member-info-top ul {
    padding-left: 0;
    margin: 0;
}

.order-receive-member-info-top ul li {
    display: inline-block;
    padding: 0px 15px;
    margin: 5px 0px;
}

.order-receive-member-info-top ul li:before {
    width: 5px;
    height: 5px;
    background: #b6b6b6;
    border-radius: 50%;
    content: '';
    position: absolute;
    margin-left: -15px;
    margin-top: 5px;
}

.order-receive-member-info-top ul li:first-child:before {
    display: none;
}

.order-receive-member-info-mid {
    width: 100%;
    float: left;
    font-size: 18px;
    font-weight: 400;
    color: #000;
    margin: 10px 0px;
    text-transform: uppercase;
    line-height: 15px;
}

.order-receive-member-info-mid ul {
    padding-left: 0;
    margin: 0;
}

.order-receive-member-info-mid ul li {
    display: inline-block;
    padding: 0px 15px;
    margin: 5px 0px;
}

.order-receive-member-info-mid ul li:before {
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 50%;
    content: '';
    position: absolute;
    margin-left: -15px;
    margin-top: 5px;
}

.order-receive-member-info-mid ul li:first-child:before {
    display: none;
}

.order-receive-member-info-bottom {
    width: 100%;
    float: left;
    text-align: center;
    margin: 15px 0px;
}

.badge-green {
    width: auto;
    padding: 4px 10px;
    background: #58c598;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 4px;
    position: relative;
    margin: 5px 0;
    margin-right: 20px;
}

.badge-green:after {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 14px solid #58c598;
    border-bottom: 14px solid transparent;
    content: '';
    position: absolute;
    right: -12px;
    top: 0;
}

.badge-pink {
    width: auto;
    padding: 4px 10px;
    background: #f64071;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 4px;
    position: relative;
    margin: 5px 0;
    margin-right: 20px;
}

.badge-pink:after {
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-left: 14px solid #f64071;
    border-bottom: 14px solid transparent;
    content: '';
    position: absolute;
    right: -12px;
    top: 0;
}

.order-receive-offer-icon {
    width: 48px;
    float: left;
    font-size: 30px;
    color: #d9d9d9;
}

.order-receive-offer-content {
    width: 100%;
    float: left;
}

.order-receive-offer-text {
    width: 100%;
    float: left;
    font-size: 20px;
    color: #000;
}

.order-receive-discount-text {
    width: 100%;
    float: left;
    font-size: 20px;
    color: #b6b6b6;
}

/* Order Received Modal End */
/* Accept Modal Start */
.accept-modal-row-set {
    width: 100%;
    float: left;
    padding: 20px 0;
    border-bottom: solid 1px #f0f0f0;
    display: flex;
    align-items: center;
}

.accept-modal-row-set:last-child {
    border-bottom: none;
}

.accept-modal-title {
    width: 100%;
    float: left;
    font-size: 24px;
    font-weight: 400;
    color: #000;
    text-align: center;
}

.rs-content {
    width: 120px;
    text-align: right;
}

.rs-text {
    width: 40px;
    float: left;
    margin-right: 15px;
}

.rs-p-or-m-text {
    width: 23px;
    text-align: right;
}

.to-pay-text {
    font-size: 20px;
    font-weight: 700;
    color: #000;
}

.accept-modal-row-set .table-sm td,
.accept-modal-row-set .table-sm th {
    padding: 0rem;
}

/* Accept Modal End */
/* Modal style end */

/* Modal custom style start */
.common-modal-content .ant-modal-content {
    border-radius: 15px;
}

.common-modal-content .ant-modal-close {
    display: none;
}

.common-modal-content .ant-modal-footer {
    padding: 15px 16px;
    border-top: none;
    display: flex;
    justify-content: center;
}

.common-modal-content .ant-modal-body {
    padding: 0px 15px;
}

.pink-button-lg.ant-btn-primary:hover,
.pink-button-lg.ant-btn-primary:focus {
    color: #fff;
    background-color: #f64071;
    border-color: #f64071;
}

.outline-secondary-btn-lg.ant-btn-primary:hover,
.outline-secondary-btn-lg.ant-btn-primary:focus {
    color: #fff;
    background-color: #f64071;
    border-color: #f64071;
}
.order-detail-modal .ant-modal-header{
    width: 100%;
    float: left;
}
.order-detail-modal .ant-modal-body{
    width: 100%;
    float: left;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.order-detail-modal .customer-details-model-header{
    padding: 15px 0;
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
}
.order-detail-modal .user-info-name {
    font-size: 14px;
}
.order-detail-modal td,
.order-detail-modal th,
.order-detail-modal tr,
.order-detail-modal table {
    padding: 2px 5px;
}
.order-detail-modal .ant-modal-footer{
    width: 100%;
    float: left;
}
/* Modal custom style end */
@media (max-width: 1440px) {
.orders-set {
    /* width: auto; */
    width: 30.333333%;
}
}
@media (max-width: 1280px) {
.orders-set {
    width: 30.333333%;
}
}
@media (min-width: 1200px) and (max-width: 1366px) {
    .orders-set {
        width: 30.555555%;
    }
}
@media (max-width: 1366px) {
    .order-incom-request-content {
        width: calc(100% - 215px);
    }
    .section-title {
    	font-size: 22px;
	}
	.order-count {
	    font-size: 36px;
	}
	.order-incom-request-content-title {
    	font-size: 14px;
	}
	.bell-request-count {
	    width: 40px;
	    height: 40px;
	    font-size: 20px;
	    line-height: 40px;
	    margin: 18px 0px;
    	margin-right: 15px;
	}
	.incoming-customer-name-badge {
    	font-size: 14px;
	}
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .orders-set {
        width: 45.999999%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .orders-set {
        width: 45.999999%;
        margin: 10px 10px;
    }
}


.order-user-profile {
    width: 57px;
    height: 57px;
    border-radius: 57px;
    border: solid 3px rgba(255, 255, 255, 0.5);
}

.order-view-modal{
  width: 900px !important;
}
.order-view-modal .table td,
.order-view-modal .table th {
      white-space: nowrap;
}

.order-view-modal .ant-modal-close{
    display: block;
}

.order-view-modal .ant-modal-close-x {
    display: block;
    width: 45px;
    height: 45px;
    font-size: 25px;
    font-style: normal;
    line-height: 45px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}
.display-block{
      display: block;
}
.page-right-content.orders-page {
  padding: 0;
}
.page-right-content.orders-page .header-container{
  display: none;
}
.loading-text{
  padding-left: 15px;
}


/* Social Icon Styles */
.social-icons-group{
  width: 100%;
  float: left;
}
.footer-social-icons {
    display:block;
}
.social-icon {
    color: #fff;
}
ul.social-icons {
    margin-top: 10px;
}
.social-icons li {
    vertical-align: top;
    display: inline;
    height: 100px;
}
.social-icons a {
    color: #fff;
    text-decoration: none !important;
}
.fa-facebook {
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #fff;
    color: #322f30;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
}
.fa-facebook:hover {
    background-color: #3d5b99;
    color: #fff;
    border: 1px solid #3d5b99;
}
.fa-twitter {
    padding:10px 12px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #fff;
    color: #322f30;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
}
.fa-twitter:hover {
    background-color: #00aced;
    color: #fff;
    border: 1px solid #00aced;
    border-radius: 4px;
}
.fa-youtube {
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #fff;
    color: #322f30;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
}
.fa-youtube:hover {
    background-color: #e64a41;
    color: #fff;
    border: 1px solid #e64a41;
}
.fa-linkedin {
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #fff;
    color: #322f30;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
}
.fa-linkedin:hover {
    background-color: #0073a4;
    color: #fff;
    border: 1px solid #0073a4;
}
.fa-instagram {
    padding:10px 14px;
    -o-transition:.5s;
    -ms-transition:.5s;
    -moz-transition:.5s;
    -webkit-transition:.5s;
    transition: .5s;
    background-color: #fff;
    color: #322f30;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
}
.fa-instagram:hover {
    background-color: #3f729b;
    color: #fff;
    border: 1px solid #3f729b;
}
.img-rounded-40{
  width: 40px;
}
.utilities-name{
  width: 100%;
  float: left;
  font-size: 16px;
}

/* New Style */
.order-user-icon {
    width: 57px;
    height: 57px;
    float: left;
    font-size: 30px;
    text-align: center;
    line-height: 57px;
    border-radius: 57px;
    border: solid 3px rgba(241, 241, 241, 0.5);
}
.order-status-btn{
    width: 26px;
    height: 26px;
    border-radius: 30px;
    font-size: 14px;
    color: #fff;
    line-height: 30px;
    text-align: center;
    position: absolute;
    top: -6px;
    right: -6px;
    outline: none;
    border: none;
    background-color: #32c74a;
    -moz-box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    -webkit-box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    padding: 0px;
}
.order-status-btn-danger{
    width: 26px;
    height: 26px;
    border-radius: 30px;
    font-size: 14px;
    color: #fff;
    line-height: 30px;
    text-align: center;
    position: absolute;
    top: -6px;
    right: -6px;
    outline: none;
    border: none;
    background-color: #dc3545;
    -moz-box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    -webkit-box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    box-shadow: 0px 0px 5px rgba(68, 68, 68, 0.2);
    padding: 0px;
}
.order-status-btn.timer{
    background-color: #ff5604;
}
.guest-time-picker {
    border-color: #40a9ff;
    border-radius: 4px;
    font-size: 14;
    color: #000;
    padding: 0px;
    width: 100%;
}
.guest-time-picker .react-time-picker {
	width: 100%;
}
.guest-time-picker .react-time-picker  input {
	width: 40px !important;
}
.guest-time-picker .react-time-picker__wrapper {    
    border: thin solid #f1f1f1;
    border-radius: 4px;
}
.example {
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 30px 50px;
    margin: 20px 0;
  }

  @media print {
    .hidePrint {
        display :  none !important;
    }
}